<template>
  <div>
    <div class="mass-action-settings" style="width: 1100px">
      <el-form
        size="small"
        ref="editForm"
        label-position="top"
        :model="actionData.element"
        :rules="elementRules"
      >
        <el-form-item label="Секция" prop="section_type">
          <el-select
            v-model="actionData.section_type"
            size="small"
            style="width: 100%"
            placeholder="Выберите секцию"
          >
            <el-option
              v-for="(sectionConfig, sectionType) in manualSections"
              :key="sectionType"
              :label="sectionConfig.showName"
              :value="sectionType"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Вес" prop="weight">
          <el-input
            v-model="actionData.element.weight"
            v-number-format
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_name')" prop="name">
          <el-input
            :maxlength="$companyConfiguration.settings.getElementsNameMaxLength()"
            :show-word-limit="!!$companyConfiguration.settings.getElementsNameMaxLength()"
            v-model="actionData.element.name"
          >
          </el-input>
        </el-form-item>
        <el-form-item :label="$companyConfiguration.i18n.t('library', 'element_edit_form_desc')" prop="desc">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 6}" v-model="actionData.element.desc"></el-input>
        </el-form-item>

        <el-form-item
          v-if="Object.keys($companyConfiguration.scales.getScales()).length > 1"
          :label="$companyConfiguration.i18n.t('library', 'element_edit_form_scale_label', 'Шкала')"
          prop="scale_type"
        >
          <el-select
            v-model="actionData.element.scale_type"
            :placeholder="$companyConfiguration.i18n.t('library', 'element_edit_form_scale_select_placeholder', 'Выберите шкалу')"
            @change="scaleTypeChanged"
          >
            <el-option
              v-for="(scale, scaleType) in $companyConfiguration.scales.getScalesForLibraryType(libraryType)"
              :key="scaleType"
              :label="scale.showName"
              :value="scaleType"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <component
        ref="elementScale"
        :is="scaleEditTemplate"
        :data="actionData.element.scale"
        :scale-name="actionData.element.scale_type"
        :scale-config="scaleEditConfig"
      ></component>


      <el-popconfirm
        style=""
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите добавить цель?"
        @confirm="doAction"
      >
        <el-button
          style="margin-top: 20px"
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Добавить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import CardsStageSelect from "@/components/filters/cards/CardsStageSelect.vue";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-add-element-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult, CardsStageSelect},

  watch: {
    'actionData.element.scale_type': function() {
      this.scale = {};
      this.scaleEditTemplate = this.$companyConfiguration.scales.getScaleTemplate(this.actionData.element.scale_type, 'libraryEditForm');
      this.scaleEditConfig = this.$companyConfiguration.scales.getScaleConfig(this.actionData.element.scale_type);
    }
  },

  computed: {
    actionButtonDisabled() {
      return false;
    },
    manualSections(){
      let sections = {};
      for (const [key, value] of Object.entries(this.$companyConfiguration.cardSections.getSections())) {
        if( !value.fromLibrary ){
          sections[key] = value;
        }
      }
      return sections;
    }
  },


  async mounted() {
    // autofill scale_type if only one in system
    if( Object.keys(this.$companyConfiguration.scales.getScales()).length === 1 ){
      await this.$nextTick(); // to make watch detect changes
      this.actionData.element.scale_type = Object.keys(this.$companyConfiguration.scales.getScales())[0];
    }

    if( Object.keys(this.manualSections).length === 1 ){
      this.actionData.section_type = Object.keys(this.manualSections)[0];
    }
  },
  data() {
    return {
      scaleEditTemplate: null,
      scaleEditConfig: null,
      elementRules: {
        name: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
        scale_type: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      },
      actionData: {
        section_type: null,
        element: {
          weight: null,
          name: null,
          desc: null,
          scale_type: null,
          scale: {},
        },
      }
    }
  },
  methods: {
    scaleTypeChanged(){
      this.actionData.element.scale = {};
    },

    doAction(){
      this.actionData.element.scale = this.$refs.elementScale.scale;
      this.$refs.editForm.validate().then(()=>{
        this.$refs.elementScale.$refs.editForm.validate().then(()=>{
          this.$emit('do-action', this.actionData)
        })
      })
    }
  },
}
</script>
